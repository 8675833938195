import { axios } from '@/utils/request'

const basic = '/manage/permission'
const api = {
  // 查询树形权限
  findTreePermission: basic + '/findTreePermission',
  getPermissionList: basic + '/getAllList',
  // 新增权限
  addPermission: basic + '/addPermission',
  // 查询菜单顶级权限
  findTopPermission: basic + '/findTopPermission',
  // 修改权限
  updatePermission: basic + '/updatePermission',
  // 删除权限
  deletePermission: basic + '/deletePermission'
}

export function findTreePermission (parameter) {
  return axios({
    url: api.findTreePermission,
    method: 'get',
    params: parameter
  })
}

export function getPermissionList (parameter) {
  return axios({
    url: api.getPermissionList,
    method: 'get',
    params: parameter
  })
}

export function findTopPermission (parameter) {
  return axios({
    url: api.findTopPermission,
    method: 'get',
    params: parameter
  })
}

export function addPermission (parameter) {
  return axios({
    url: api.addPermission,
    method: 'post',
    data: parameter
  })
}

export function updatePermission (parameter) {
  return axios({
    url: api.updatePermission,
    method: 'post',
    data: parameter
  })
}

export function deletePermission (parameter) {
  return axios({
    url: api.deletePermission,
    method: 'post',
    data: parameter
  })
}
