<template>
  <a-modal :title="editModalTitle" :maskClosable="false" v-model="visible" :confirmLoading="confirmLoading" @ok="editHandleOk" @cancel="editHandleCancel" width="550px" class="edit-permission-component">
    <a-form :form="form" @submit="editHandleOk">
      <a-form-item label="权限编码" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-input v-decorator="nameValidate" :disabled="disabledNameInput" placeholder="权限唯一标识" />
      </a-form-item>
      <a-form-item label="权限描述" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-input v-decorator="descriptionValidate" placeholder="权限说明" />
      </a-form-item>
      <a-form-item label="权限URL" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-input v-decorator="urlValidate" placeholder="URL" />
      </a-form-item>
      <a-form-item label="权限类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-select v-decorator="methodValidate" placeholder="请选择">
          <a-select-option key="menu" value="menu">菜单</a-select-option>
          <a-select-option key="action" value="action">按钮</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="父菜单" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-cascader v-decorator="pidValidate" :options="permissions" expand-trigger="hover" placeholder="请选择" changeOnSelect @change="onChangePermission" />
      </a-form-item>
      <a-form-item label="图标" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-input v-decorator="iconValidate" @change="iconChange">
          <a-icon slot="prefix" :type="iconType" />
        </a-input>
      </a-form-item>
      <a-form-item label="是否隐藏" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-switch v-decorator="hiddenValidate" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { addPermission, updatePermission, findTreePermission } from '@/api/manage/permission'
import IconSelector from '@/components/IconSelector'

export default {
  name: 'EditPermissionComponent',
  props: {
    showEditModal: Boolean,
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  components: { IconSelector },
  watch: {
    showEditModal: function(newVal, oldVal) {
      this.visible = newVal
      this.createForm()
    },
    editData: function(newVal, oldVal) {
      this.createForm()
      this.newEditData = newVal
      this.nameValidate[1].initialValue = this.newEditData.name
      this.descriptionValidate[1].initialValue = this.newEditData.description
      this.iconType = this.iconValidate[1].initialValue = this.newEditData.icon
      if (!this.newEditData.icon && !this.iconType) {
        this.iconType = 'edit'
      }
      this.urlValidate[1].initialValue = this.newEditData.url
      if (this.newEditData.pid !== 0) {
        this.pidValidate[1].initialValue = this.newEditData.treeNode
          .split('.')
          .slice(1)
          .map(item => Number(item))
      }
      this.methodValidate[1].initialValue = this.newEditData.method
      this.hiddenValidate[1].initialValue = this.newEditData.hidden
      // 未传递edit对象，则为新增
      if (Object.keys(this.newEditData).length === 0) {
        this.editModalTitle = '新增权限'
        this.disabledNameInput = false
      } else {
        this.editModalTitle = '编辑权限'
        this.disabledNameInput = true
      }
    }
  },
  data() {
    return {
      disabledNameInput: false,
      permissions: [],
      iconType: 'edit',
      confirmLoading: false,
      visible: false,
      nameValidate: ['name', { rules: [{ required: true, message: '权限编码不能为空！' }] }],
      descriptionValidate: ['description', { rules: [{ required: true, message: '权限描述不能为空！' }] }],
      iconValidate: ['icon', { rules: [{ required: true, message: '图标不能为空！' }] }],
      urlValidate: ['url', { rules: [{ required: true, message: '权限URL不能为空！' }] }],
      methodValidate: ['method', { rules: [{ required: true, message: '权限类型不能为空！' }] }],
      pidValidate: ['pid', { rules: [] }],
      hiddenValidate: ['hidden', { rules: [], valuePropName: 'checked' }],
      form: null,
      newEditData: {},
      editModalTitle: '新增权限'
    }
  },
  beforeCreate() {
    if (this.form === null) {
      this.form = this.$form.createForm(this)
    }
  },
  created() {
    this.createForm()
    // 未传递edit对象，则为新增
    if (Object.keys(this.editData).length === 0) {
      this.editModalTitle = '新增权限'
      this.disabledNameInput = false
    } else {
      this.editModalTitle = '编辑权限'
      this.disabledNameInput = true
    }
    this.findTreePermission()
  },
  methods: {
    findTreePermission() {
      findTreePermission().then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }

        // 递归处理权限树
        const rev = item => {
          const result = {
            value: item.id,
            label: item.description,
            children: item.children
          }

          if (item.children && item.children.length > 0) {
            result.children = item.children.map(item => rev(item))
          } else {
            delete result.children
          }

          return result
        }

        this.permissions = res.body.map(item => rev(item))
      })
    },
    onChangePermission(data) {
      console.log(data)
    },
    iconChange(val) {
      this.iconType = val.srcElement.value
    },
    createForm() {
      if (this.form === null) {
        this.form = this.$form.createForm(this)
      }
    },
    editHandleCancel() {
      this.form.resetFields()
      this.$emit('cancel')
    },
    editPermissionSubmit(values) {
      if (this.newEditData && this.newEditData.id) {
        values.id = this.newEditData.id
        return updatePermission(values)
      } else {
        return addPermission(values)
      }
    },
    editHandleOk() {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        this.confirmLoading = true

        if (values.pid) {
          values.treeNode = '0.' + values.pid.join('.')
          values.pid = values.pid[values.pid.length - 1]
        } else {
          values.treeNode = '0'
          values.pid = 0
        }
        values.type = 1
        values.hidden = values.hidden === undefined ? false : values.hidden

        this.editPermissionSubmit(values)
          .then(res => {
            if (res.status !== 200) {
              this.$notification.error({
                message: '提示',
                description: res.message
              })
              return
            }
            this.$emit('ok')
            this.$notification.success({
              message: '提示',
              description: res.message
            })
            this.form.resetFields()
          })
          .catch(e => {
            this.$notification.error({
              message: '提示',
              description: e.message
            })
          })
          .finally(() => {
            this.confirmLoading = false
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.edit-permission-component {
}
</style>
