<template>
  <a-card class="permission-manage-view">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="12" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="queryClick">刷新</a-button>
            </span>
          </a-col>
          <a-col :md="12" :sm="24">
            <span style="float: right">
              <a-button type="primary" icon="plus" @click="addClick">添加权限</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table :columns="columns" :dataSource="data" rowKey="id" :pagination="false" :loading="loading">
      <span slot="icon" slot-scope="record">
        <a-icon :type="record" />
        {{ record }}
      </span>
      <span slot="method" slot-scope="record">
        <a-icon :type="record === 'menu' ? 'link' : 'tool'" />
        {{ record === 'menu' ? '菜单' : '按钮' }}
      </span>
      <span slot="hidden" slot-scope="record">
        <a-icon :type="record ? 'eye-invisible' : 'eye'" />
        {{ record ? '隐藏' : '显示' }}
      </span>
      <span slot="action" slot-scope="text">
        <a-button type="primary" size="small" @click="updClick(text)">编辑</a-button>
        <a-divider type="vertical" />
        <a-popconfirm title="权限与角色的关联关系也将被删除！确认删除？" okText="是" cancelText="否" @confirm="delOk(text.id)">
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </span>
    </a-table>

    <edit-permission-component :showEditModal="showEditModal" :editData="editData" @cancel="cancelHandle" @ok="editHandle"></edit-permission-component>
  </a-card>
</template>

<script>
import * as permissionApi from '@/api/manage/permission'
import EditPermissionComponent from './children/EditPermissionComponent'

export default {
  name: 'PermissionManageView',
  components: {
    EditPermissionComponent
  },
  data() {
    return {
      editData: {},
      showEditModal: false,
      permissionApi,
      // 表格数据
      data: [],
      loading: false,
      columns: [
        {
          title: '权限描述',
          dataIndex: 'description',
          width: 300
        },
        {
          title: '权限编码',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '权限url',
          dataIndex: 'url',
          width: 300
        },
        {
          title: '类型',
          dataIndex: 'method',
          width: 80,
          scopedSlots: { customRender: 'method' }
        },
        {
          title: '图标',
          dataIndex: 'icon',
          width: 100,
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '隐藏',
          dataIndex: 'hidden',
          width: 80,
          scopedSlots: { customRender: 'hidden' }
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created() {
    this.query()
  },
  methods: {
    updClick(row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.showEditModal = true
    },
    addClick() {
      this.showEditModal = true
    },
    cancelHandle() {
      this.showEditModal = false
      this.editData = {}
    },
    editHandle() {
      this.cancelHandle()
      this.query()
    },
    handleOk() {
      this.visible = false
    },
    delOk(id) {
      permissionApi.deletePermission({ id: id }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        if (this.data.length === 1 && this.pagination.total > 1) {
          this.queryParam.page -= 1
          this.pagination.current -= 1
        } else if (this.data.length === 1 && this.pagination.total === 1) {
          this.queryParam.page = 0
          this.pagination.current = 1
        }
        this.query()
      })
    },
    queryClick() {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    query() {
      this.loading = true
      permissionApi
        .findTreePermission()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.data = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less">
.permission-manage-view {
}
</style>
